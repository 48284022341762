import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/index.js";
import { Button } from '../components/Button';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Markdown Example`}</h1>
    <p>{`This is a markdown (`}<inlineCode parentName="p">{`.md`}</inlineCode>{` or `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{`) file that is being transformed with `}<a parentName="p" {...{
        "href": "https://gatsby-mdx.netlify.com/"
      }}><inlineCode parentName="a">{`gatsby-mdx`}</inlineCode></a>{`.`}</p>
    <p>{`What's cool about it is you can mix markdown with React components:`}</p>
    <p>
  <Button onClick={() => alert('You clicked the button! 🍪')} mdxType="Button">I'm a React Button Component 🙌</Button>
    </p>
    <p>{`and the `}<inlineCode parentName="p">{`<ResetStyle />`}</inlineCode>{` component in the `}<inlineCode parentName="p">{`<Layout />`}</inlineCode>{` is providing a consistent style for all the pages with minimal effort`}</p>
    <p>{`You can also mix `}<inlineCode parentName="p">{`.js`}</inlineCode>{` and `}<inlineCode parentName="p">{`.md`}</inlineCode>{` or `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` files in your pages folder as needed`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      